/*==================  RD Parallax    ======================*/
.parallax {
  position: relative;
  overflow: hidden;

  &_image, &_pattern{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-position: center center;
    will-change: transform;
  }

  &_image{
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
  }

  &_pattern{
    background-repeat: repeat;
  }

  &_cnt{
    position: relative;
  }
}