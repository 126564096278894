/*
* @subsection   RD Mail Form
*
* @description  Describes style declarations for RD Mail Form extension
*
* @author       Evgeniy Gusarov
* @link         https://ua.linkedin.com/pub/evgeniy-gusarov/8a/a40/54a
* @version      1.1.2
*/
.subscribe-form {
  display: inline-block;
  width: 100%;
  max-width: 588px;

  .rd-mailform {
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }

  .mfControls {
    display: block;
    margin-top: 20px;

    button {
      display: inline-block;
      padding: 11px 17px;
      width: 169px;
    }
  }

  @media (min-width: 992px) {
    .rd-mailform {
      display: flex;
    }

    .form-wrap {
      flex: 0 1 100%;
    }

    .mfControls {
      margin-top: 0;
      margin-left: 5px;
    }
  }
}








