/*
* @subsection   RD Mail Form
*
* @description  Describes style declarations for RD Mail Form extension
*
* @author       Evgeniy Gusarov
* @link         https://ua.linkedin.com/pub/evgeniy-gusarov/8a/a40/54a
* @version      1.1.2
*/
.booking-form{


$input-height: 55px;
$input-font-weight: inherit;
$input-font-size: 16px;
$input-line-height: 34px;

$input-offset-top: 10px;

$input-color: #817F7F;
$input-background: $contrast;

$input-border-radius: 3px;

$input-border-color: #e9e6e6;
$input-border: 2px solid $input-border-color;

$input-padding-vertical: ($input-height - $input-line-height) / 2;
$input-padding-horizontal: 18px;
$input-padding: $input-padding-vertical $input-padding-horizontal;

$textarea-height: 191px;

$placeholder-clr: $input-color;
$placeholder-opacity: 1;

.rd-mailform {
  position: relative;
  text-align: left;
  margin-left: auto;
  margin-right: auto;

  fieldset {
    border: none;

    > .row + .row{
      margin-top: $input-offset-top;
    }
  }
}

.rd-mailform label {
  // position: relative;
  display: block;
  width: 100%;
  margin-top: $input-height / 2;

  &:first-child {
    margin-top: 0;
  }

  input, select {
    height: $input-height;
  }

  input, textarea {
    display: block;
    width: 100%;
    margin: 0;
    -webkit-appearance: none;
    font-size: $input-font-size;
    line-height: $input-line-height;
    color: $input-color;
    background: $input-background;

    @if ($input-border != none) {
      border: $input-border;
      border-radius: $input-border-radius;
    }

    &:-moz-placeholder {
      color: $placeholder-clr;
      opacity: $placeholder-opacity;
    }

    &::-webkit-input-placeholder {
      color: $placeholder-clr;
      opacity: $placeholder-opacity;
    }

    &::-moz-placeholder {
      color: $placeholder-clr;
      opacity: $placeholder-opacity;
    }

    &:-ms-input-placeholder {
      color: $placeholder-clr;
      opacity: $placeholder-opacity;
    }
  }

  textarea {
    resize: vertical;
    overflow: auto;
    min-height: $input-height;
    height: $textarea-height;
    max-height: $textarea-height * 1.5;
  }

  @media (min-width: 768px) {
    margin-top: $input-offset-top;
  }
}

/*
* MF Controls
*/

.mfControls {

  button {
    margin-top: 30px;
    padding: 21px 17px;
    width: 100%;
  }
}

@import "mailform_modules_booking/_mf-animation.scss";
@import "mailform_modules_booking/_mf-placeholder.scss";
@import "mailform_modules_booking/_mf-validation.scss";
@import "mailform_modules_booking/_mf-submit.scss";
@import "mailform_modules_booking/_mf-icon.scss";
//@import "mailform_modules_booking/_mf-select.scss";
//@import "mailform_modules_booking/_mf-datepicker.scss";

}








