@import "_mixins.scss";
@import "_variables.scss";
@import "animate.css";

// Fonts
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css);
@import url(//fonts.googleapis.com/css?family=Roboto:400,500);
@import "fl-bigmug-line.css";
/*========================================================
                      Main Styles
=========================================================*/
body {
    background: $footer-bg;
    color: $default;
    font: 400 14px/28px $default-font;
    -webkit-text-size-adjust: none;
}

img {
    max-width: 100%;
    height: auto;
}

h1, h2, h3, h4, h5, h6 {
    color: $secondary;
}

h1{
    font-size: 60px;
    line-height: 93px;
}

h2{
	font-size: 56px;
    line-height: 93px;
}

h3{
	font-size: 45px;
    line-height: 72px;
}

h4{
	font-size: 24px;
    line-height: 28px;    
}

h5{
	font-size: 20px;
    line-height: 24px; 
}

h6{
	font-size: 18px;
    line-height: 22px;
}

p, .marked-list, .list, cite{
	a:hover{
        text-decoration: underline;
    }

    .fa{
    	text-decoration: none;
    }
}

time {
   display: block;
}

a {
    font: inherit;
    color: inherit;
    text-decoration: none;

    &:focus{
        outline: none;
    }

    &:active{
        background-color: transparent;
    }

    @include transition(.3s);
}

a[href^="tel:"],
a[href^="callto:"] {
    color: inherit;
    text-decoration: none;
}

strong {
    font-weight: 700;
}

small {
    font-size: 12px;
}

.fa{
	line-height: inherit;
}

[class*='fa-']{
    &:before{
        font-weight: 400;
    	font-family: $fa;
    }     
}

.page {
    overflow: hidden;
    min-height: 500px;

    .lt-ie9 & {
        min-width: 1200px;
    }
}

.center {
    text-align: center;
}

.mobile-center {
    @media (max-width: 767px) {
        text-align: center;
    }
}

.mg-add1 {
    margin-top: 13px;
}

.mg-add2 {
    margin-top: 18px;
}

/*=======================================================
                       Main Layout
=========================================================*/
* + p {
    margin-top: 15px;
}

img + * {
    margin-top: 25px;
}

* + .row {
    margin-top: 49px;
}

.img1 + * {
    margin-top: 29px;
}

h3 + p {
    margin-top: 21px;
}

h3 + .off1 {
    margin-top: 21px;
}

h3 + .off2 {
    margin-top: 40px;
}

h3 + .off3 {
    margin-top: 30px;
}

h3 + .flex-list {
    margin-top: 18px;
}

p + .subscribe-form {
    margin-top: 30px;
}

.contact-list + .inline-list {
    margin-top: 55px;
}
/*=======================================================
                        Components
=========================================================*/

/*==================     Brand     ======================*/
.brand {
    display: inline-block;
    &:before {
        position: relative;
        top: -2px;
        left: -5px;
        display: inline-block;
        width: 60px;
        height: 63px;

        content: "";

        background: url(../images/logo.png);
    }

    a {
        font-size: 34px;
        line-height: 63px;
        color: $primary;
    }
}

/*==================  Backgrounds  ======================*/
.primary {
    color: $primary;

    a {
        @include transition(0.3s all ease);
        &:hover {
            color: $secondary;
        }
    }
}

.bg-primary{
    background: $primary;
    h3,
    h4,
    p {
        color: #fff;
    }
}

.bg-secondary2 {
    background: $secondary2;
}

/*==================     Icons     ======================*/
.fa-facebook,
.fa-twitter,
.fa-google-plus,
.fa-youtube{
    font-size: 20px;
    line-height: 27px;
    color: lighten(saturate(adjust_hue(#333333, 0), 0%), 65.09803921568627%);
    @include transition(0.3s all ease);

    &:hover {
        color: #333;
    }
}

.img1 {
    font-size: 75px;
    line-height: 75px;
    color: #fff;
}

/*==================    Buttons    ======================*/
.btn {
    display: block;
    padding: 16px 17px;
    
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    text-align: center;

    color: #fff;
    background: $primary;
    border: 2px solid $primary;
    border-right-color: desaturate(darken($primary, 5%), 11%);
    border-bottom-color: desaturate(darken($primary, 5%), 11%);
    
    @include border-radius(3px);
    @include box-shadow(3px 3px 5px 0 rgba(0,0,0,.17));
    @include transition(0.3s all ease);
    
    &:hover{
        background: desaturate(darken($primary, 3%), 6%);
        border-top-color: darken(desaturate(adjust_hue($primary, 0.40251572327042595), 12.33353063036401%), 8.627450980392155%);
        border-right-color: desaturate(darken($primary, 3%), 6%);
        border-bottom-color: desaturate(darken($primary, 3%), 6%);
        border-left-color: darken(desaturate(adjust_hue($primary, 0.40251572327042595), 12.33353063036401%), 8.627450980392155%);
        @include box-shadow(0 0 0 0 rgba(0,0,0,.17));
    }

    &:active{
        background: desaturate(darken($primary, 8%), 6%);
    }
}

/*==================      Box      ======================*/
.box {
    @include clearfix();

    &_aside{
        float: left;
        padding-right: 29px;

        img {
            margin-top: 11px;
            border: 2px solid #fff;

            @include border-radius(3px);
        }
    }

    &_cnt{

    }

    &_cnt__no-flow{
        overflow: hidden;
    }

    @media (max-width: 479px) {
        &_aside,
        &_cnt {
            float: none;

        }

        &_aside {
            display: block;
            text-align: center;
            padding-right: 0;
        }

        &_aside + &_cnt {
            margin-top: 30px;
        }
    }
}
/*================      Flex List     ===================*/

.flex-list {
    display: table;
    table-layout: fixed;
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
    @include box-sizing(border-box);

    li {
        display: table-cell;
        text-align: center;
    }

    @media (max-width: 1199px) {
        padding: 0;
    }

    @media (max-width: 767px) {
        
        li {
            display: block;

            & + li {
                margin-top: 16px;
            }
        }
    }

    @media (max-width: 479px) {
        text-align: center;
        li {
            display: inline-block;
        }
    }
}
/*================    Inline List     ===================*/

.inline-list {
    @include clearfix();

    li {
        float: left;

        & + li {
            margin-left: 24px;
        }
    }

    @media (max-width: 767px) {
        text-align: center;
        li {
            float: none;
            display: inline-block;
        }
    }
}

/*================    Product List   ====================*/

.product-list {
    li {
        position: relative;
        padding-left: 120px;

        &:before {
            position: absolute;
            top: 4px;
            left: 0;
            width: 92px;
            height: 92px;

            font-size: 35px;
            line-height: 92px;
            text-align: center;
            
            color: #fff;
            background: $primary;

            @include border-radius(50%);
        }
    }

    li + li {
        margin-top: 33px;
    }
}

/*================   Contact List  ====================*/

.contact-list {
    li {
        position: relative;
        padding-left: 55px;
        font-size: 16px;

        &:before {
            position: absolute;
            top: 50%;
            left: 0;

            margin-top: -14px;

            font-size: 28px;
            color: $primary;
        }

        a:hover {
            color: $primary;
        }

        & + li {
            margin-top: 16px;
        }
    }

    @media (max-width: 479px) {
        li {
            padding-left: 46px;
        }
    }
}

/*================   Pricing Table  ==================*/

.pricing-table > div {
    padding: 23px 30px 31px;

    background: #fff;

    @include border-radius(3px);
    @include box-sizing(border-box);

    h4 {
        color: $primary;
         @media (max-width: 767px) {
            text-align: center;
        }
    }

    h4 + .price {
        margin-top: 10px;
    }

    .price + ul {
        margin-top: 8px;
    }

    ul {
        position: relative;
        border-bottom: 3px solid darken(saturate(adjust_hue($secondary2, 0), 6.382978723404229%), 4.901960784313729%);
        @media (max-width: 767px) {
            text-align: center;
        }
        &:before {
            content: "";
            position: absolute;
            top: -3px;
            left: -30px;
            right: -30px;
            border-top: 3px solid darken(saturate(adjust_hue($secondary2, 0), 6.382978723404229%), 4.901960784313729%);
        }

        &:after {
            content: "";
            position: absolute;
            bottom: -3px;
            left: -30px;
            right: -30px;
            border-bottom: 3px solid darken(saturate(adjust_hue($secondary2, 0), 6.382978723404229%), 4.901960784313729%);
        }
        
        li {
            position: relative;
            padding: 16px 0 15px;
        }

        li + li {

            &:before {
                content: "";
                position: absolute;
                top: -1px;
                left: -30px;
                right: -30px;
                height: 1px;
                background: darken(saturate(adjust_hue($secondary2, 0), 6.382978723404229%), 4.901960784313729%);
            }
        }
    }

    ul + .btn {
        margin-top: 29px;
    }

    @media (max-width: 979px) and (min-width: 767px) {
        padding: 23px 10px 31px;

        ul {
            &:before {
                left: -10px;
                right: -10px;   
            }

            &:after {  
                left: -10px;
                right: -10px;  
            }
        }
    }
}

.price {
    vertical-align: baseline;
    
    
    span {
        
        font-size: 56px; 
        color: #000;
        line-height: 63px;
        vertical-align: baseline
    }
    
    span + span {
        font-size: 16px;
        line-height: 63px;
        vertical-align: baseline;
        color: $default;
    }
    
     @media (max-width: 767px) {
        text-align: center;
    }
}

/*================       Quote      ====================*/

blockquote {
    padding: 18px 29px 22px;
    background: $secondary2;
    border: 1px solid darken(saturate(adjust_hue($secondary2, 0), 6.382978723404229%), 4.901960784313729%);

    @include border-radius(3px);

    & + & {
        margin-top: 30px;
    }
}

q {
    font-size: 24px;
    line-height: 38px;

    &:before {
        position: relative;
        top: 22px;
        left: -6px;

        content: "“";
        font-family: Roboto;
        font-size: 74px;
        line-height: 38px;
        vertical-align: bottom;
        color: $primary;
    }

    @media (max-width: 479px) {
        font-size: 20px;
        line-height: 24px;

        &:before {
            line-height: 24px;
            top: 16px;
            left: -2px;
        }
    }
}

cite {
    display: block;
    color: $primary;
}

p + cite {
    margin-top: 13px;
}

//==================    Imports    ======================

@import "_to-top.scss";
@import "_accordion.scss";
@import "_parallax.scss";
@import "_rd-mailform-booking.scss";
@import "_rd-mailform-subscribe.scss";
@import "_rd-mailform-contact.scss";
@import "_snackbars.scss";
/*========================================================
                     HEADER  Section
=========================================================*/
header {
    padding-top: 85px;
    padding-bottom: 107px;
    background: $header-bg url(../images/header-bg.jpg) left bottom no-repeat;
    background-size: 60%;
    @include clearfix;

    .brand{
        float: right;
    }

    h2 {
        float: right;
        width: 560px;
        display: inline-block;
        clear: right;
        text-align: right;
    }

    

    @media (max-width: 1199px) {
        background: $header-bg;
        text-align: center;

        h2,
        .brand,
        .booking-form {
            float: none;
        }

        h2 {
            text-align: center;
        }
    }

    @media (max-width: 767px) {
        h2 {
            width: 100%;
            font-size: 46px;
            line-height: 48px;
        }
    }

    @media (max-width: 479px) {
        h2 {
            font-size: 36px;
            line-height: 40px;
        }

        .brand {
            &:before {
                display: inline-block;
            }

            a {
                display: inline-block;
                font-size: 28px;
                line-height: 28px;
            }
        }
    }
}

 

/*========================================================
                     CONTENT  Section
=========================================================*/
main {
    display: block;
    background: $content-bg;
}

.video {
    position: relative;
    width: 100%;
    padding-bottom: 61.4035088%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.txt1 {
    font-size: 24px;
    line-height: 38px;
}

@media (max-width: 979px) {
    h3 {
        font-size: 32px;
        line-height: 36px;
    }
}

@media (max-width: 767px) {
    article + article {
        margin-top: 50px!important;
    }
}    


/*==================      Well     ======================*/
.well {
    padding-top: 81px;
    padding-bottom: 100px;
}

.well2 {
    padding-top: 71px;
    padding-bottom: 88px;
}

.well3 {
    padding-top: 89px;
    padding-bottom: 106px;
}

.well4 {
    padding-top: 73px;
    padding-bottom: 106px;
}

.well5 {
    padding-top: 86px;
    padding-bottom: 116px;
}

.well6 {
    padding-top: 53px;
    padding-bottom: 63px;
}



.ins1 {
    padding-top: 76px;
}

.ins2 {
    padding-top: 86px;
}

.ins3 {
    padding-top: 108px;
}
/*========================================================
                     FOOTER  Styles
=========================================================*/
footer{
    background: $footer-bg;

    @media (max-width: 767px) {
        h3,
        .copyright {
            text-align: center;
        }
    }
}

.copyright {
    font-size: 12px;
    margin-top: -12px;
}

* + .copyright {
    margin-top: 20px;
}