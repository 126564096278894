/*
* @subsection   RD Mail Form
* @description  Describes style declarations for RD Mail Form extension
*/
.contact-form {
  .rd-mailform {
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }

  .mfControls {
    margin-top: 34px;
    display: inline-block;

    button {
      width: 169px;
      padding: 11px 17px;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      display: inline-block;
      cursor: pointer;
    }
  }
}
