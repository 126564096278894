/*==================     ToTop     ======================*/
.toTop {
  width: 40px;
  height: 40px;
  font-size: 24px;
  line-height: 40px;
  color: #fff;
  background: $secondary;

  position: fixed;
  right: 66px;
  bottom: 180px;

  display: none;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  z-index: 20;

  &:hover {
    color: #fff;
    background: $primary;
    text-decoration: none;
  }
}

@media only screen and (max-width: 1399px) {
  .toTop {
    display: none !important;
  }
}

