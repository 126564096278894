/*==================   Accordion    ======================*/
.accordion {
}

.accordion_header {
  padding: 14px 46px 14px 29px;
  border: 2px solid lighten(saturate(adjust_hue(#888888, 0), 6.382978723404229%), 37.45098039215686%);
  
  @include border-radius(3px);
  @include transition(0.3s all ease);
  
  &:hover {
    border-color: $primary;
  }
}

.accordion_cnt {
  padding: 19px 31px 34px;

  background: $secondary2;
  border: 1px solid lighten(saturate(adjust_hue(#888888, 0), 6.382978723404229%), 37.45098039215686%);
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.ui-accordion-header-active {
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.ui-accordion-header {
  position: relative;
  cursor: pointer;

  &:after {
    position: absolute;
    top: 18px;
    right: 20px;

    content: "\e001";
    font: 400 24px/20px "fl-bigmug-line";

    color: $primary;
  }

  &-active:after {
    color: lighten(saturate(adjust_hue($default, 0), 0.6134969325153352%), 14.705882352941181%);
    content: "\e06e";
  }

  &:focus {
    outline: none;
  }
}

.accordion_cnt + .accordion_header {
  margin-top: 10px;
}